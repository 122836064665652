/* Tailwind CSS 설정 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 커스텀 CSS 파일 임포트 */
@import 'video.js/dist/video-js.min.css';
@import '@/styles/videojs-style.css';

/* 폰트 설정 */
@font-face {
    font-family: 'ChosunGs';
    src: url('@/assets/fonts/ChosunGs.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Sen';
    src: url('@/assets/fonts/Sen-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sen-Bold';
    src: url('@/assets/fonts/Sen-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

/* NotoSans KR */
@font-face {
    font-family: 'NotoSansKR';
    src: url('@/assets/fonts/NotoSansKR-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansKR';
    src: url('@/assets/fonts/NotoSansKR-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansKR';
    src: url('@/assets/fonts/NotoSansKR-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansKR';
    src: url('@/assets/fonts/NotoSansKR-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansKR';
    src: url('@/assets/fonts/NotoSansKR-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansKR';
    src: url('@/assets/fonts/NotoSansKR-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

/* 커스텀 CSS style 설정 */

body {
    font-family: NotoSansKR, sans-serif;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    body {
        -webkit-font-smoothing: antialiased;
    }
}

@media screen and (-moz-osx-font-smoothing: grayscale) {
    body {
        -moz-osx-font-smoothing: grayscale;
    }
}

.main_shadow {
    box-shadow: 0 1px 8px 0 rgba(25, 29, 136, 0.2);
}

.primary-shadow {
    box-shadow: 0 15px 50px rgba(246, 111, 77, 0.16);
}

.primary-shadow-thick {
    box-shadow: 0 10px 50px rgba(246, 111, 77, 0.336);
}

.border-shadow {
    box-shadow: 0 24px 90px rgba(192, 188, 161, 0.157);
}

.button-shadow {
    box-shadow: 0 24px 90px rgba(192, 188, 161, 0.24);
}

.button-shadow-focus {
    box-shadow: 0 24px 90px rgba(146, 144, 126, 0.24);
}

.course-shadow {
    box-shadow: 0 1px 8px 0 rgba(25, 29, 136, 0.2);
}

.banner-title-font-style {
    font-size: 40px;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
}

.board-background-style {
    background: url(@/assets/newImg/bg-board-category.svg) center center repeat;
    background-size: auto 100%;
}

.banner-bg-style {
    background: linear-gradient(180deg, #BADAFE 0%, #3E86D8 100%);

}

.main-notice-title-style {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.course-title-style {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.course-list-title-style {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


@keyframes lineAnimation {
    0% {
        width: 0;
    }
    100% {
        width: 80%;
    }
}

.nav-item {
    position: relative;
}

.nav-item:hover:after,
.nav-item.nav-selected:after {
    content: '';
    position: absolute;
    left: 0;
    top: 28px;
    height: 0;
    border-radius: 8px;
    border: 2px solid #337CCF;
    width: 80%;
    animation: lineAnimation 0.5s;
}

/* 모바일 뷰 */
@media (max-width: 768px) {
    @keyframes lineAnimation {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }
    .nav-item:hover:after,
    .nav-item.nav-selected:after {
        top: auto;
        bottom: 0;
        width: 100%;
    }
}

.button-success {
    color: #337CCF;
    border-color: #337CCF;
    background-color: #D6E5F5;
}

.no-drag {
    user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
    color: black;
}

.font-ChosunGs {
    font-family: ChosunGs, sans-serif;
    /*font-weight: lighter;*/
}

@media print {
    display: block;
}

.contain-modal::-webkit-scrollbar {
    display: none;
}