.ql-toolbar {
    display: block;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
    border-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.ql-container {
    border: 1px solid #e5e5e5 !important;
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 17px 30px !important;
}

.ql-editor {
    height: 450px;
}
