.vjs-lecture-on .vjs-big-play-button {
  border: none;
  background-color: #2e476b;
}

.vjs-lecture-on .vjs-control {
  flex-direction: row;
}

.vjs-lecture-on .vjs-control-bar {
  font-size: 120%;
  flex-direction: row;
  align-items: center;
}

.vjs-lecture-on .vjs-progress-control {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
